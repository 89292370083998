import { Routes, Route } from 'react-router-dom';
import TripQueryComponent from './TripQueryComponent';
import TripDetailComponent from './TripDetailComponent';
import ProfileComponent from './ProfileComponent';
import QuickSightDashboardComponent from './QuickSightDashboardComponent';
import ViolationsComponent from './ViolationsComponent';

function AppRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<TripQueryComponent />} />
      <Route path="/trip/:tripId" element={<TripDetailComponent />} />
      <Route path="/userProfiles" element={<ProfileComponent />} />
      <Route path="/analytics" element={<QuickSightDashboardComponent />} />
      <Route path="/violations" element={<ViolationsComponent />} />
    </Routes>
  );
}

export default AppRouter;