import React from 'react';
import { View, Flex, Loader, Text } from '@aws-amplify/ui-react';

function Loading({ message = 'Loading...' }) {
  return (
    <View padding="1rem" height="70vh">
      <Flex direction="column" alignItems="center" justifyContent="center" height="100%">
        <Loader size="large" scale="150%" marginBottom="1rem" />
        <Text textAlign="center" fontSize="1.2rem">{message}</Text>
      </Flex>
    </View>
  );
}

export default Loading;
