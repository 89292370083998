import { useState, useEffect } from 'react';

interface PaginationState<T> {
  currentItems: T[];
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  setCurrentPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}

function usePagination<T>(
  data: T[], 
  initialItemsPerPage: number,
  externalCurrentPage?: number
): PaginationState<T> {
  const [internalCurrentPage, setInternalCurrentPage] = useState(externalCurrentPage ?? 1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

  // Use external page when provided, otherwise use internal
  const currentPage = externalCurrentPage ?? internalCurrentPage;

  // Update internal page when external page changes
  useEffect(() => {
    if (externalCurrentPage !== undefined) {
      setInternalCurrentPage(externalCurrentPage);
    }
  }, [externalCurrentPage]);

  const totalPages = Math.max(1, Math.ceil(data.length / itemsPerPage));
  
  // Ensure current page is within bounds
  const validCurrentPage = Math.min(Math.max(1, currentPage), totalPages);
  
  const currentItems = data.slice(
    (validCurrentPage - 1) * itemsPerPage,
    validCurrentPage * itemsPerPage
  );

  return {
    currentItems,
    currentPage: validCurrentPage,
    totalPages,
    itemsPerPage,
    setCurrentPage: setInternalCurrentPage,
    setItemsPerPage,
  };
}

export default usePagination;