import { useRef, useMemo } from 'react';
import { View, Flex, Card, Heading, TextField, Button } from '@aws-amplify/ui-react';
import { UserViolation, ViolationQueryParams, ViolationQueryState } from '../types/violation';
import { useViolationContext } from '../contexts/ViolationContext';
import { formatDate } from '../utils/formatDate';
import { usePersistedState } from '../hooks/usePersistedState';
import downloadCSV from '../utils/downloadCSV';
import { generateColumns, filterColumns } from '../utils/tableUtils';
import { tableStyle } from '../styles/tableStyle';
import Loading from './common/Loading';
import DataTable from './common/DataTable';
import { AlertView } from './common/Alert';

export default function ViolationsComponent(): JSX.Element {
    const { data: users, loading, error, fetchData: fetchUsers, clearData: clearUsers }: { data: UserViolation[], loading: boolean, error: string | null, fetchData: (queryParams: ViolationQueryParams) => Promise<void>, clearData: () => void } = useViolationContext();
    const filteredDataGetter = useRef<(() => UserViolation[]) | null>(null);

    const getDefaultState = (): ViolationQueryState => {
        const defaultEndDate: Date = new Date();
        const defaultStartDate: Date = new Date(defaultEndDate);
        defaultStartDate.setDate(defaultStartDate.getDate() - 2);

        return {
            startDate: formatDate(defaultStartDate),
            endDate: formatDate(defaultEndDate),
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortField: '',
            sortDirection: 'asc'
        }
    }

    const [queryState, setQueryState] = usePersistedState<ViolationQueryState>({
        storageKey: 'ViolationState',
        defaultValue: getDefaultState()
    });

    const setCurrentPage = (currentPage: number) => 
        setQueryState(prev => ({ ...prev, currentPage }));
    const setItemsPerPage = (newItemsPerPage: number) => 
        setQueryState(prev => ({ 
            ...prev, 
            itemsPerPage: newItemsPerPage,
            currentPage: 1  // Reset to first page when changing items per page
        }));
    const setStartDate = (startDate: string) => 
        setQueryState(prev => ({ ...prev, startDate }));
    const setEndDate = (endDate: string) => 
        setQueryState(prev => ({ ...prev, endDate }));
    const resetToDefaults = () => {
        setQueryState(getDefaultState());
        clearUsers();
    };

    const handleDownloadCSV = () => {
        if (filteredDataGetter.current) {
            const filteredData = filteredDataGetter.current();
            downloadCSV(filteredData, 'user_data.csv');
        }
    };

    const handleFindClick = async (): Promise<void> => {
        await fetchUsers({
            startDate: queryState.startDate,
            endDate: queryState.endDate
        });
    };

    // Generate columns dynamically when trips data is available
    const columns = useMemo(() => {
        const defaultColumns = [
            { field: 'user_uuid', label: 'User UUID' },
            { field: 'Highway Trips', label: 'In Corridor' },
            { field: 'completed_trips', label: 'Completed Trips' },
            { field: 'count_of_trip', label: 'Violations' },
        ];

        if (!users.length) return defaultColumns;
        
        const allColumns = generateColumns(users);
        const excludeFields: string[] = [];
        return filterColumns(allColumns, excludeFields);
    }, [users]);

    const paginationState = {
        currentPage: queryState.currentPage,
        itemsPerPage: queryState.itemsPerPage,
        onPageChange: setCurrentPage,
        onItemsPerPageChange: setItemsPerPage
    };

    return (
        <View padding="1rem">
            <Flex direction="row" alignItems="flex-end" marginBottom="1rem">
                <Card variation="outlined" marginRight="1rem" style={tableStyle}>
                    <Heading level={3}>Trip Violations</Heading>
                    <Flex direction="row" alignItems="flex-end">
                        <TextField
                            label="Start Date"
                            type="date"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={queryState.startDate}
                            marginRight="1rem"
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={queryState.endDate}
                            marginRight="1rem"
                        />
                        <Button onClick={handleFindClick} style={{ backgroundColor: 'green', color: 'white' }}>Find</Button>
                    </Flex>
                </Card>
                <Button onClick={resetToDefaults} style={tableStyle}>Reset</Button>
                <Button 
                    onClick={handleDownloadCSV} 
                    style={tableStyle}
                >
                    Download CSV
                </Button>
            </Flex>
            {error ? (
                <AlertView message="An error has occurred." onAction={handleFindClick} buttonTitle='Retry' />
            ) : loading ? (
                <Loading message={'Loading user data...'} />
            ) : (
                <DataTable
                    data={users}
                    columns={columns}
                    searchableFields={['user_uuid', 'user_id']}
                    tableStyle={tableStyle}
                    getFilteredData={filteredDataGetter}
                    paginationState={paginationState}
                />
            )}
        </View>
    );
}