import { useState, useCallback, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { TripProvider } from './contexts/TripContext';
import { UserProvider } from './contexts/UserContext';
import { QuickSightProvider } from './contexts/QuickSightContext';
import { ViolationProvider } from './contexts/ViolationContext';
import { getValidAuthToken } from './utils/auth';
import { ErrorBoundary } from './components/ErrorBoundary';
import awsExports from './aws-exports';
import AppContent from './components/AppContent';
import logger from './services/logger';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

export default function App(): JSX.Element {
  const [authToken, setAuthToken] = useState<string | null>(null);

  const updateAuthToken = useCallback(async () => {
    try {
      const token = await getValidAuthToken();
      setAuthToken(token as string | null);
    } catch (error) {
      await logger.log(`Failed to update auth token: ${error}`, { source: { file: __filename } });
      setAuthToken(null);
    }
  }, []);

  useEffect(() => {
    // Refresh token every 55 minutes (considering 1 hour expiration)
    const refreshInterval = setInterval(updateAuthToken, 55 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, [updateAuthToken]);

  return (
    <ErrorBoundary>
      <Router basename='/'>
        <Authenticator
          variation="modal"
          components={{
            SignUp: {
              Footer() {
                return null;
              },
            },
          }}
          hideSignUp={true}
        >
          {({ user }) => (
            <ViolationProvider>
              <TripProvider>
                <UserProvider>
                  <QuickSightProvider>
                    <AppContent initialUser={user} updateAuthToken={updateAuthToken} />
                  </QuickSightProvider>
                </UserProvider>
              </TripProvider>
            </ViolationProvider>
         )}
        </Authenticator>
      </Router>
    </ErrorBoundary>
  );
}
