import React, { useRef } from 'react';
import { Button, Heading, View, Flex, TextField, Card } from '@aws-amplify/ui-react';
import downloadCSV from '../utils/downloadCSV';
import { useUserContext } from '../contexts/UserContext';
import DataTable from './common/DataTable';
import { generateColumns, filterColumns } from '../utils/tableUtils';
import Loading from './common/Loading';
import { AlertView } from './common/Alert';
import { usePersistedState } from '../hooks/usePersistedState';
import { tableStyle } from '../styles/tableStyle';
import { UserQueryState, UserQueryParams, UserProfile } from '../types/user';

function ProfileComponent(): JSX.Element {
    const filteredDataGetter = useRef<(() => UserProfile[]) | null>(null);
    const { data: users, loading, error, fetchData: fetchUsers, clearData: clearUsers }: { data: UserProfile[], loading: boolean, error: string | null, fetchData: (queryParams: UserQueryParams) => Promise<void>, clearData: () => void } = useUserContext();

    const getDefaultState = (): UserQueryState => {
        const defaultEndDate = new Date();
        const defaultStartDate = new Date(defaultEndDate);
        defaultStartDate.setDate(defaultStartDate.getDate() - 2);

        return {
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortField: '',
            sortDirection: 'asc',
            phoneNumber: '',
        };
    };

    const [queryState, setQueryState] = usePersistedState<UserQueryState>({
        storageKey: 'UserQueryState',
        defaultValue: getDefaultState()
    });

    const setCurrentPage = (currentPage: number) => {
        setQueryState(prev => ({ ...prev, currentPage}));
    }

    const setItemsPerPage = (itemsPerPage: number) => {
        setQueryState(prev => ({ ...prev, itemsPerPage}));
    }

    const setPhoneNumber = (phoneNumber: string) => {
        setQueryState(prev => ({ ...prev, phoneNumber}));
    }

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.replace(/\D/g, '');
        setPhoneNumber(value);
    };

    const resetToDefaults = () => {
        setQueryState(getDefaultState());
        clearUsers();
    };

    const handleFindClick = () => {
        clearUsers();
        fetchUsers({ phoneNumber: queryState.phoneNumber });
    };

    const handleDownloadCSV = () => {
        if (filteredDataGetter.current) {
            const filteredData = filteredDataGetter.current();
            downloadCSV(filteredData, 'trip_data.csv');
        }
    };

    const columns = React.useMemo(() => {
        const defaultColumns = [
            { field: 'user_uuid', label: 'UUID' },
            { field: 'user_id', label: 'User ID' },
            { field: 'created_on', label: 'Created On' },
            { field: 'modified_on', label: 'Modified On' },
            { field: 'In Corridor', label: 'In Corridor' },
            { field: 'completed_trips', label: 'Completed Trips' },
            { field: 'violations', label: 'Violations' },
        ];

        if (!users.length) return defaultColumns;
        
        const allColumns = generateColumns(users);
        const excludeFields = ['_internal_id', 'created_at', 'updated_at', 'signed_up_next_round', 'confirmed', 'inactive'];
        return filterColumns(allColumns, excludeFields);
    }, [users]);

    const paginationState = {
        currentPage: queryState.currentPage,
        itemsPerPage: queryState.itemsPerPage,
        onPageChange: setCurrentPage,
        onItemsPerPageChange: setItemsPerPage
    };

    return (
        <View padding="1rem">
            <Flex direction="row" alignItems="flex-end" marginBottom="1rem">
                <Card variation="outlined" marginRight="1rem" style={tableStyle}>
                    <Heading level={3}>User Profiles</Heading>
                    <Flex direction="row" alignItems="flex-end">
                        <TextField
                            label="Phone Number"
                            type="tel"
                            onChange={handlePhoneNumberChange}
                            value={queryState.phoneNumber}
                            placeholder="Enter phone number"
                            marginRight="1rem"
                        />
                    <Button 
                        onClick={handleFindClick} 
                        style={{ backgroundColor: 'green', color: 'white' }}
                        isDisabled={!queryState.phoneNumber}
                    >
                        Find
                    </Button>
                    </Flex>
                </Card>
                <Button onClick={resetToDefaults} style={tableStyle}>Reset</Button>
                <Button onClick={handleDownloadCSV} style={tableStyle}>Download CSV</Button>
            </Flex>
            {error ? (
                <AlertView message="An error has occurred." onAction={handleFindClick} buttonTitle='Retry' />
            ) : loading ? (
                <Loading message={'Loading trip data...'} />
            ) : (
                <DataTable
                    data={users}
                    columns={columns}
                    onRowClick={() => { }}
                    searchableFields={['phone_number']}
                    tableStyle={tableStyle}
                    getFilteredData={filteredDataGetter}
                    paginationState={paginationState}
                />
            )}
        </View>
    );
}

export default ProfileComponent;
