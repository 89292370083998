import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { View, Heading, Button, Flex, Table, TableCell, TableBody, TableRow } from '@aws-amplify/ui-react';
import { useTripContext } from '../contexts/TripContext';
import { Trip } from '../types/trip';
import { tableStyle, thStyle } from '../styles/tableStyle';

function TripDetailComponent(): JSX.Element {
  const { tripId } = useParams<{ tripId: string }>();
  const navigate = useNavigate();
  const { data: trips }: { data: Trip[] } = useTripContext();
  const trip: Trip | undefined = trips.find((t: Trip) => t.trip_id === tripId);

  const handleBack = (): void => {
    navigate(-1);
  };

  if (!trip) {
    return <div>Trip not found</div>;
  }

  return (
    <View padding="1rem">
      <Flex direction="row" justifyContent="space-between" alignItems="center" marginBottom="1rem">
        <Button onClick={handleBack}>Back to List</Button>
        <Heading level={2}>Trip Details</Heading>
      </Flex>
      <Table style={tableStyle}>
        <TableBody>
          <TableRow>
            <TableCell as="th" style={thStyle}>User UUID</TableCell>
            <TableCell>{trip.user_uuid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Trip ID</TableCell>
            <TableCell>{trip.trip_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Vehicle ID</TableCell>
            <TableCell>{trip.vehicle_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>License Plate</TableCell>
            <TableCell>{trip.license_plate ? trip.license_plate.split('_').pop() || trip.license_plate : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Start Time</TableCell>
            <TableCell>{new Date(trip.trip_start_time).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>End Time</TableCell>
            <TableCell>{new Date(trip.end_time).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Occupancy</TableCell>
            <TableCell>{trip.occupancy}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>In Corridor</TableCell>
            <TableCell>{trip.in_corridor ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Valid</TableCell>
            <TableCell>{trip.valid ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Transponder ID</TableCell>
            <TableCell>{trip.transponder_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Ambient Light Reverify</TableCell>
            <TableCell>{trip.ambient_light_reverify}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Ambient Light Verify</TableCell>
            <TableCell>{trip.ambient_light_verify}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Attempts Reverify</TableCell>
            <TableCell>{trip.attempts_reverify}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Passenger Camera Dwell Time</TableCell>
            <TableCell>{trip.passenger_camera_dwell_time}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Reverify Camera Dwell Time 1</TableCell>
            <TableCell>{trip.reverify_camera_dwell_time_1}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Reverify Camera Dwell Time 2</TableCell>
            <TableCell>{trip.reverify_camera_dwell_time_2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Auto Close</TableCell>
            <TableCell>{trip.auto_close === 'true' ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Started Offline</TableCell>
            <TableCell>{trip.started_offline === true ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Ended Offline</TableCell>
            <TableCell>{trip.ended_offline === true ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>No GPS</TableCell>
            <TableCell>
              {trip.no_gps === null || trip.no_gps === undefined || trip.no_gps === '' ? 'Null' : trip.no_gps === 'true' ? 'Yes' : trip.no_gps === 'false' ? 'No' : 'No'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th" style={thStyle}>Reported Time</TableCell>
            <TableCell>
              {trip.reported_time
                ? new Date(trip.reported_time.replace(' ', 'T')).toLocaleString('en-US', { timeZone: 'America/New_York' })
                : 'N/A'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </View>
  );
}

export default TripDetailComponent;
