import { useEffect, useState, useCallback, useRef } from 'react';
import { Button, View, Flex, Text, Image, Menu, MenuItem } from '@aws-amplify/ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthUser, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import AppRouter from './AppRouter';
import rideflagLogo from '../images/rideflaglogo.png';
import activityEvents from '../services/activityEvents';
import logger from '../services/logger';
import { hasAdminAccess } from '../utils/auth';

const INACTIVITY_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
const LAST_ACTIVITY_KEY = 'lastActivityTimestamp'; // Key for localStorage

interface AppContentProps {
  initialUser: AuthUser | undefined;
  updateAuthToken: () => Promise<void>;
}

export default function AppContent({ initialUser, updateAuthToken }: AppContentProps): JSX.Element {
  const [user, setUser] = useState<AuthUser | undefined>(initialUser);
  const navigate = useNavigate();
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
  const lastActivityRef = useRef(Date.now());

  // Store the last activity timestamp in local storage so that the inactivity is accounted for even when the broswer tab is closed 
  const updateLastActivity = useCallback((): void => {
    const timestamp = Date.now();
    lastActivityRef.current = timestamp;
    localStorage.setItem(LAST_ACTIVITY_KEY, timestamp.toString()); 
  }, []);

  const handleSignOut = useCallback(async (): Promise<void> => {
    try {
      await signOut();
      setUser(undefined);
      await updateAuthToken();
      localStorage.removeItem(LAST_ACTIVITY_KEY);
      navigate('/');
    } catch (error) {
      await logger.log(`Error signing out: ${error}`, { source: { file: __filename } });
    }
  }, [navigate, updateAuthToken]);

  const checkInactivity = useCallback((): void => {
    const currentTime = Date.now();
    const lastActivity = lastActivityRef.current;
    const timeSinceLastActivity = currentTime - lastActivity;

    if (timeSinceLastActivity >= INACTIVITY_TIMEOUT) {
      handleSignOut();
    } else {
      inactivityTimerRef.current = setTimeout(checkInactivity, 60000); 
    }
  }, [handleSignOut]);

  const resetInactivityTimer = useCallback((): void => {
    updateLastActivity();
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(checkInactivity, 60000); 
  }, [updateLastActivity, checkInactivity]);

  const handleAuthToken = useCallback(async (): Promise<void> => {
    try {
      await updateAuthToken(); 
    } catch (error) {
      await logger.log(`Error updating auth token: ${error}`, { source: { file: __filename } });
      await handleSignOut();
    }
  }, [updateAuthToken, handleSignOut]);

  useEffect(() => {
    const checkIfInactiveOnLoad = (): void => {
      const lastActivityTimestamp = localStorage.getItem(LAST_ACTIVITY_KEY);
      if (lastActivityTimestamp) {
        const timeSinceLastActivity = Date.now() - parseInt(lastActivityTimestamp, 10);
        if (timeSinceLastActivity >= INACTIVITY_TIMEOUT) {
          handleSignOut();
        }
      }
    };

    if (user) {
      updateLastActivity();
      checkIfInactiveOnLoad(); // Check if the user should be logged out on tab reopen
      handleAuthToken();
      resetInactivityTimer();

      const handleUserActivity = (): void => {
        resetInactivityTimer();
      };

      activityEvents.forEach(event => {
        window.addEventListener(event, handleUserActivity);
      });

      return () => {
        activityEvents.forEach(event => {
          window.removeEventListener(event, handleUserActivity);
        });
        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
        }
      };
    }
  }, [user, handleAuthToken, resetInactivityTimer, handleSignOut, updateLastActivity]);

  useEffect(() => {
    const handleAuthError = async (data: any): Promise<void> => {
      if (data.payload.event === 'signOut' || data.payload.event === 'tokenRefresh_failure') {
        await handleSignOut();
      }
    };

    const hubListener = Hub.listen('auth', handleAuthError);

    return () => {
      hubListener();
    };
  }, [handleSignOut]);

  const navigateTo = (path: string): void => {
    navigate(path);
    resetInactivityTimer();
  };

  return (
    <View>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="1rem"
        backgroundColor="var(--amplify-colors-background-secondary)"
      >
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Flex direction="row" alignItems="center">
            <Image
              src={rideflagLogo}
              alt="RideFlag Logo"
              width="50px"
              height="50px"
              objectFit="contain"
            />
            <Text fontSize="1.2rem" fontWeight="bold" marginLeft="1rem" color="green">
              RideFlag
            </Text>
          </Flex>
        </Link>
        <Flex direction="row" alignItems="center">
          <Menu
            trigger={
              <Button variation="link" color="green">
                Menu
              </Button>
            }
          >
            <MenuItem onClick={() => navigateTo("/")} >
              Trip Query
            </MenuItem>
            {user && hasAdminAccess(user) && (
              <>
                <MenuItem onClick={() => navigateTo("/userProfiles")} >
                  User Profiles
                </MenuItem>
              </>
            )}
            <MenuItem onClick={() => navigateTo("/violations")} >
              Violations
            </MenuItem>
            <MenuItem onClick={() => navigateTo("/analytics")}>
              Analytics
            </MenuItem>
          </Menu>
          {user && (
            <>
              <Text marginRight="1rem">Welcome, {user.signInDetails?.loginId}</Text>
              <Button onClick={handleSignOut}>Sign out</Button>
            </>
          )}
        </Flex>
      </Flex>
      <AppRouter />
    </View>
  );
}