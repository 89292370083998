// components/DataTable.tsx
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Flex, 
  Text, 
  SearchField,
  SelectField,
  Pagination
} from '@aws-amplify/ui-react';
import usePagination from '../../hooks/usePagination'; 

interface Filter {
  field: string;
  value: string | number;
}

interface DataTableProps<T> {
  data: T[];
  columns: { field: string; label: string; render?: (value: any, item: any) => React.ReactNode }[];
  onRowClick?: (item: any) => void;
  initialSort?: { field: string; direction: string };
  initialItemsPerPage?: number; 
  searchableFields?: string[];
  tableStyle?: React.CSSProperties;
  getFilteredData?: React.MutableRefObject<(() => T[]) | null>;
  onFilteredDataChange?: (filteredData: number) => void; // Having this method on top of getFilteredData may seem redundant, but the difference is getFilteredData is reactive and doesn't pass the value back down to DataTable. getFilteredData is designed to be non-reactive to prevent endless recursive calling.
  additionalFilters?: Filter[];
  paginationState?: {
    currentPage: number; // Passed down from the parent since the state is stored in the parent component 
    itemsPerPage: number; // Passed down from the parent since the state is stored in the parent component 
    onPageChange: (page: number) => void; // Datatable sets the current page. It gets stored by the parent
    onItemsPerPageChange: (itemsPerPage: number) => void;  // DataTable sets the items per page. It gets stored by the parent
  };
}

function DataTable<T>({ 
  data = [],
  columns = [],
  onRowClick,
  initialSort = { field: '', direction: 'asc' },
  initialItemsPerPage = 10,
  searchableFields = [],
  tableStyle = {},
  getFilteredData,
  onFilteredDataChange,
  additionalFilters = [],
  paginationState,
}: DataTableProps<T>) {
  const [filteredData, setFilteredData] = useState<any[]>(data);
  const filteredDataRef = useRef<any[]>(data);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortField, setSortField] = useState<string>(initialSort.field);
  const [sortDirection, setSortDirection] = useState<string>(initialSort.direction);

  // Handle search and additional filters
  const applyFilters = useMemo(() => {
    return (items: any[]) => {
      return items.filter(item => 
        searchableFields.some(field => 
          String(item[field] ?? '').toLowerCase().includes(searchTerm.toLowerCase())
        ) &&
        additionalFilters.every(filter => {
          const itemValue = item[filter.field];
          const filterValue = filter.value;
          
          // Handle numeric comparison
          if (typeof itemValue === 'number' && typeof filterValue === 'number') {
            return itemValue === filterValue;
          }
          
          // Fall back to string comparison
          return String(itemValue ?? '').toLowerCase().includes(String(filterValue).toLowerCase());
        })
      );
    };
  }, [searchTerm, searchableFields, additionalFilters]);

  useEffect(() => {
    const filtered = applyFilters(data);
    const sorted = [...filtered].sort((a, b) => {
      if (!sortField) return 0;

      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredData(sorted);
    filteredDataRef.current = sorted;
    // Consider using `setFilteredData` instead to reduce redundancy if more components require passing data upstream to the parent component. However, beware of the endless recursive loop.
    if (onFilteredDataChange) {
      onFilteredDataChange(sorted.length);
    }
  }, [searchTerm, data, sortField, sortDirection, searchableFields, applyFilters, onFilteredDataChange]);

  // Expose the getter function to the parent
  useEffect(() => {
    if (getFilteredData) {
      getFilteredData.current = () => filteredDataRef.current;
    }
  }, [getFilteredData]);

  // Use the usePagination hook
  const {
    currentItems,
    currentPage,
    totalPages,
    itemsPerPage,
    setCurrentPage: setInternalCurrentPage,
    setItemsPerPage: setInternalItemsPerPage,
  } = usePagination(
    filteredData, 
    paginationState?.itemsPerPage ?? initialItemsPerPage,
    paginationState?.currentPage
  );

  // Sorting
  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortField(field);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const SortableHeader = ({ field, label }: { field: string, label: string }) => (
    <TableCell as="th" onClick={() => handleSort(field)}>
      <Flex alignItems="center">
        {label}
        {sortField === field && (
          <Text marginLeft="0.5rem">
            {sortDirection === 'asc' ? '▲' : '▼'}
          </Text>
        )}
      </Flex>
    </TableCell>
  );

  // Handlers for Pagination UI
  const handlePageChange = (newPage: number | undefined) => {
    if (!newPage) return;
    
    if (paginationState) {
      paginationState.onPageChange(newPage);
    } else {
      setInternalCurrentPage(newPage);
    }
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(event.target.value);
    
    if (paginationState) {
      paginationState.onItemsPerPageChange(newItemsPerPage);
    }
    setInternalItemsPerPage(newItemsPerPage);
    // Reset to first page when changing items per page
    handlePageChange(1);
  };

  return (
    <>
      <SearchField
        label="Search"
        placeholder="Enter search term"
        onChange={(e) => setSearchTerm(e.target.value)}
        onClear={() => setSearchTerm('')}
        value={searchTerm}
        marginBottom="1rem"
        isDisabled={!data.length}
      />

      <Table style={tableStyle}>
        <TableHead>
          <TableRow>
            {columns.map(({ field, label }) => (
              <SortableHeader 
                key={field} 
                field={field} 
                label={label}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <TableRow 
                key={index}
                onClick={() => onRowClick?.(item)}
                style={{ 
                  cursor: onRowClick ? 'pointer' : 'default',
                  transition: 'background-color 0.3s',
                  height: '50px',
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--amplify-colors-background-secondary)'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
              >
                {columns.map(({ field, render }) => (
                  <TableCell 
                    key={field}
                    style={{ padding: '4px 8px' }}
                  >
                    {render ? render(item[field], item) : item[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell 
                colSpan={columns.length} 
                style={{ 
                  textAlign: 'center',
                  padding: '4px 8px'
                }}
              >
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Flex justifyContent="space-between" alignItems="center" marginTop="1rem">
        <SelectField
          label="Items per page"
          value={String(paginationState?.itemsPerPage ?? itemsPerPage)}
          onChange={handleItemsPerPageChange}
          isDisabled={!filteredData.length}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </SelectField>
        <Pagination
          currentPage={paginationState?.currentPage ?? currentPage}
          totalPages={totalPages}
          onNext={() => handlePageChange(
            (paginationState?.currentPage ?? currentPage) + 1
          )}
          onPrevious={() => handlePageChange(
            (paginationState?.currentPage ?? currentPage) - 1
          )}
          onChange={handlePageChange}
          isDisabled={!filteredData.length}
        />
      </Flex>
    </>
  );
}

export default DataTable;