const convertToCSV = <T extends Record<string, any>>(data: T[]): string => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(trip => 
        Object.values(trip).map(value => {
            if (value === null || value === undefined) {
                return '""';
            } else if (typeof value === 'object') {
                if (value instanceof Date) {
                    return `"${value.toLocaleString()}"`;
                } else {
                    return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
                }
            } else if (typeof value === 'string') {
                return `"${value.replace(/"/g, '""')}"`;
            } else {
                return value;
            }
        }).join(',')
    );
    return [headers, ...rows].join('\n');
};

const downloadCSV = <T extends Record<string, any>>(data: T[], filename: string): void => {
    if (data.length === 0) {
        return;
    }
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export default downloadCSV;