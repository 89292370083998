interface Column {
  field: string;
  label: string;
  render?: ((value: string) => string | null) | null;
  sortable: boolean;
  visible: boolean;
}

type FieldLabels = {
  'trip_id': string;
  'trip_start_time': string;
  'vehicle_id': string;
  'transponder_id': string;
  'in_corridor': string;
  'total_owned_trips': string;
  'user_uuid': string;
  [key: string]: string; 
};

// Custom labels
const FIELD_LABELS: FieldLabels = {
  'trip_id': 'Trip ID',
  'trip_start_time': 'Start Time',
  'vehicle_id': 'Vehicle ID',
  'transponder_id': 'Transponder ID',
  'in_corridor': 'In Corridor',
  'total_owned_trips': 'Total Trips',
  'user_uuid': 'UUID',
  'user_id': 'User ID',
  'count_of_trip': 'Violations',
  'CountOftrip_id': 'Violations',
  'highway_trips': 'Highway Trips',
  'completed_trips': 'Completed Trips'
};

// Define field type patterns for special rendering
const DATE_PATTERNS = ['_time', '_date', 'timestamp'];
const BOOLEAN_PATTERNS = ['is_', 'has_', '_flag', 'valid', 'in_corridor'];

// Convert snake_case to Title Case
function formatFieldName(field: string): string {
  return field
    .split(/[_\s]/) // Split by both underscore and space
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

// Determine if a field should be rendered as a date
function isDateField(field: string): boolean {
  return DATE_PATTERNS.some(pattern => field.toLowerCase().includes(pattern));
}

// Determine if a field should be rendered as a boolean
function isBooleanField(field: string): boolean {
  return BOOLEAN_PATTERNS.some(pattern => field.toLowerCase().includes(pattern));
}

// Generate render function based on field type
function getRenderFunction(field: string): ((value: string) => string | null) | null {
  if (isDateField(field)) {
    return (value: string) => value ? new Date(value).toLocaleString() : '';
  }
  if (isBooleanField(field)) {
    return (value: string) => value ? 'Yes' : 'No';
  }
  if (field.includes('license_plate')) {
    return (value: string) => value?.split('_').pop() || value;
  }
  return null; 
}

export function generateColumns(data: Record<string, any>[]): Column[] {
  if (!data || data.length === 0) return [];

  const sampleData = data[0];
  
  return Object.keys(sampleData).map(field => ({
    field,
    label: FIELD_LABELS[field] || formatFieldName(field),
    render: getRenderFunction(field),
    sortable: true,
    visible: !field.startsWith('_') // Hide fields starting with underscore
  }));
}

// Filter out unwanted columns
export function filterColumns(columns: Column[], excludeFields: string[] = []): Column[] {
  return columns.filter(col => 
    !excludeFields.includes(col.field) && 
    col.visible !== false
  );
} 