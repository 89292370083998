import { useState, useCallback, useMemo, useRef } from 'react';
import { Text, Button, Heading, View, Flex, TextField, SelectField, Card } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useTripContext } from '../contexts/TripContext';
import downloadCSV from '../utils/downloadCSV';
import DataTable from './common/DataTable';
import { generateColumns, filterColumns } from '../utils/tableUtils';
import { Trip, TripQueryState, TripQueryParams } from '../types/trip';
import Loading from './common/Loading';
import { usePersistedState } from '../hooks/usePersistedState';
import { formatDate } from '../utils/formatDate';
import { AlertView } from './common/Alert';
import { tableStyle } from '../styles/tableStyle';
import { responsiveTripQueryCardStyle } from '../styles/responsiveTripQueryCardStyle';

function TripQueryComponent(): JSX.Element {
    const navigate = useNavigate();
    const { data: trips, loading, error, fetchData: fetchTrips, clearData: clearTrips }: { data: Trip[], loading: boolean, error: string | null, fetchData: (queryParams: TripQueryParams) => Promise<void>, clearData: () => void } = useTripContext();
    const filteredDataGetter = useRef<(() => Trip[]) | null>(null);
    const [tripCount, setTripCount] = useState<number>(trips.length);

    const getDefaultState = (): TripQueryState => {
        const defaultEndDate = new Date();
        const defaultStartDate = new Date(defaultEndDate);
        defaultStartDate.setDate(defaultStartDate.getDate() - 2);

        return {
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortField: '',
            sortDirection: 'asc',
            phoneNumber: '',
            autoCloseFilter: '',
            startDate: formatDate(defaultStartDate),
            endDate: formatDate(defaultEndDate),
            licensePlate: '',
            grantedFilter: '',
            validFilter: ''
        };
    };

    const [queryState, setQueryState] = usePersistedState<TripQueryState>({
        storageKey: 'tripQueryState',
        defaultValue: getDefaultState()
    });

    const setCurrentPage = (currentPage: number) => 
        setQueryState(prev => ({ ...prev, currentPage }));

    const setItemsPerPage = (newItemsPerPage: number) => 
        setQueryState(prev => ({ 
            ...prev, 
            itemsPerPage: newItemsPerPage,
            currentPage: 1  // Reset to first page when changing items per page
        }));

    const resetToDefaults = () => {
        setQueryState(getDefaultState());
        clearTrips();
    };

    const handleFindClick = (): void => {
        fetchTrips({ startDate: queryState.startDate, endDate: queryState.endDate });
    };

    const handleDownloadCSV = () => {
        if (filteredDataGetter.current) {
            const filteredData = filteredDataGetter.current();
            downloadCSV(filteredData, 'trip_data.csv');
        }
    };

    const handleFilteredDataChange = useCallback((filteredDataCount: number) => {
        setTripCount(filteredDataCount);
    }, []);

    // Generate columns dynamically when trips data is available
    const columns = useMemo(() => {
        const defaultColumns = [
            { field: 'trip_id', label: 'Trip ID' },
            { field: 'trip_start_time', label: 'Start Time' },
            { field: 'end_time', label: 'End Time' },
            { field: 'occupancy', label: 'Occupancy' },
            { field: 'in_corridor', label: 'In Corridor' },
            { field: 'valid', label: 'Valid' },
            { field: 'transponder_id', label: 'Transponder ID' },
            { field: 'no_gps', label: 'No GPS' },
            { field: 'license_plate', label: 'License Plate' },
        ];

        if (!trips.length) return defaultColumns;
        
        const allColumns = generateColumns(trips);
        const excludeFields = [
            '_internal_id', 
            'created_at', 
            'updated_at', 
            `user_uuid`, 
            'vehicle_id', 
            'ambient_light_reverify', 
            'ambient_light_verify', 
            'app_load_time', 
            'attempts_reverify', 
            'passenger_camera_dwell_time', 
            'reverify_camera_dwell_time_1', 
            'reverify_camera_dwell_time_2', 
            'auto_close', 
            'started_offline', 
            'ended_offline', 
            'user_id',
            'reported_time'
        ];
        return filterColumns(allColumns, excludeFields);
    }, [trips]);

    const additionalFilters = useMemo(() => [
        { field: 'user_id', value: queryState.phoneNumber },
        { field: 'license_plate', value: queryState.licensePlate },
        { field: 'auto_close', value: queryState.autoCloseFilter },
        { field: 'in_corridor', value: queryState.grantedFilter ? Number(queryState.grantedFilter) : '' },
        { field: 'valid', value: queryState.validFilter ? Number(queryState.validFilter) : '' },
    ], [queryState.phoneNumber, queryState.licensePlate, queryState.autoCloseFilter, 
        queryState.grantedFilter, queryState.validFilter]);

    const paginationState = {
        currentPage: queryState.currentPage,
        itemsPerPage: queryState.itemsPerPage,
        onPageChange: setCurrentPage,
        onItemsPerPageChange: setItemsPerPage
    };

    return (
        <View padding="1rem">
            <Flex 
                direction="row" 
                alignItems="end"
                style={responsiveTripQueryCardStyle.outerFlex}
            >
                <Card 
                    variation="outlined" 
                    style={{...tableStyle, ...responsiveTripQueryCardStyle.card}}
                >
                    <Flex
                        justifyContent={'space-between'}
                    >
                        <Heading level={3}>Filters</Heading>
                        <div>
                            <Text style={responsiveTripQueryCardStyle.tripCountText}>Trip Count</Text>
                            <View
                                backgroundColor="white"
                                padding="0.5rem 1rem"
                                border="1px solid #ccc"
                                borderRadius="4px"
                            >
                                <Text fontSize="1.1rem">
                                    {tripCount} trips
                                </Text>
                            </View>
                        </div>
                    </Flex>
                    <Flex 
                        direction="row" 
                        alignItems="flex-end"
                        style={responsiveTripQueryCardStyle.filtersFlex}
                    >
                        <TextField
                            label="Phone Number"
                            type="tel"
                            onChange={(e) => setQueryState({ ...queryState, phoneNumber: e.target.value })}
                            value={queryState.phoneNumber}
                            placeholder="Enter phone number"
                            style={responsiveTripQueryCardStyle.inputField}
                        />
                        <TextField
                            label="License Plate"
                            onChange={(e) => setQueryState({ ...queryState, licensePlate: e.target.value })}
                            value={queryState.licensePlate}
                            placeholder="Enter license plate"
                            style={responsiveTripQueryCardStyle.inputField}
                        />
                        <SelectField
                            label="Auto-Close"
                            onChange={(e) => setQueryState({ ...queryState, autoCloseFilter: e.target.value })}
                            value={queryState.autoCloseFilter}
                            style={responsiveTripQueryCardStyle.dropDownFields}
                        >
                            <option value="">All</option>
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </SelectField>
                        <SelectField
                            label="In Corridor"
                            onChange={(e) => setQueryState({ ...queryState, grantedFilter: e.target.value })}
                            value={queryState.grantedFilter}
                            style={responsiveTripQueryCardStyle.dropDownFields}
                        >
                            <option value="">All</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </SelectField>
                        <SelectField
                            label="Valid"
                            onChange={(e) => setQueryState({ ...queryState, validFilter: e.target.value })}
                            value={queryState.validFilter}
                            style={responsiveTripQueryCardStyle.dropDownFields}
                        >
                            <option value="">All</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </SelectField>
                   </Flex>
                </Card>

                <Card 
                    variation="outlined" 
                    style={{...tableStyle, ...responsiveTripQueryCardStyle.dateCard}}
                >
                    <Heading level={3}>Date Range</Heading>
                    <Flex 
                        direction="row" 
                        alignItems="flex-end"
                        style={responsiveTripQueryCardStyle.filtersFlex}
                    >
                        <TextField
                            label="Start Date"
                            type="date"
                            onChange={(e) => setQueryState({ ...queryState, startDate: e.target.value })}
                            value={queryState.startDate}
                            style={responsiveTripQueryCardStyle.inputField}
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            onChange={(e) => setQueryState({ ...queryState, endDate: e.target.value })}
                            value={queryState.endDate}
                            style={responsiveTripQueryCardStyle.inputField}
                        />
                        <Button 
                            onClick={handleFindClick} 
                            style={{ backgroundColor: 'green', color: 'white' }}
                        >
                            Find
                        </Button>
                    </Flex>
                </Card>

                <Flex gap="1rem" wrap="wrap">
                    <Button 
                        onClick={resetToDefaults} 
                        style={{...tableStyle, ...responsiveTripQueryCardStyle.actionButton}}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={handleDownloadCSV} 
                        style={{...tableStyle, ...responsiveTripQueryCardStyle.actionButton}}
                    >
                        Download CSV
                    </Button>
                </Flex>
            </Flex>
            {error ? (
                <AlertView message={"An error has occurred"} onAction={handleFindClick} buttonTitle='Retry'/>
            ) : loading ? (
                <Loading message={'Loading trip data...'} />
            ) : (
                <DataTable
                    data={trips}
                    columns={columns}
                    onRowClick={(trip: Trip) => navigate(`/trip/${trip.trip_id}`)}
                    searchableFields={['trip_id', 'transponder_id', 'license_plate']}
                    tableStyle={tableStyle}
                    getFilteredData={filteredDataGetter}
                    onFilteredDataChange={handleFilteredDataChange}
                    additionalFilters={additionalFilters}
                    paginationState={paginationState}
                />
            )}
        </View>
    );
}

export default TripQueryComponent;
