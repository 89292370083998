export const responsiveTripQueryCardStyle = {
    outerFlex: {
        gap: '1rem',
        flexWrap: 'wrap' as const,
        alignItems: 'end'
    },
    card: {
        flex: '1',
        minWidth: '300px', // Minimum width before wrapping
        maxWidth: '920px',
        marginBottom: '1rem',
    },
    dateCard: {
        flex: '0 1 auto', // Don't grow, can shrink, use auto base size
        minWidth: '300px',
        maxWidth: '600px', 
        marginBottom: '1rem',
    },
    filtersFlex: {
        gap: '1rem',
        flexWrap: 'wrap' as const,
    },
    inputField: {
        flex: '1',
        minWidth: '180px', 
        maxWidth: '200px'
    },
    dropDownFields: {
        flex: '1',
        minWidth: '100px'
    },
    tripCountText: {
        flex: '1',
        maxWidth: '150px',
        marginBottom: '0.3rem'
    },
    actionButton: {
        minWidth: '120px',
        marginBottom: '1rem',
    }
};